body {
  padding: 0;
  margin: 0;
  font: 14px 'Roboto';
  text-decoration: none;
  background-color: #edf1f5;
}

.full-screen {
  background-color: #edf1f5;
}
